<template>
  <vs-card id="users-view">
    <div slot="header">
      <vs-row vs-align="center">
        <vs-col vs-w="12">
          <p class="text-base">
            {{
              lang.users.generalSettings.passwordExpiration[languageSelected]
            }}
          </p>
        </vs-col>
      </vs-row>
    </div>
    <div class="sm:w-full md:w-full lg:w-2/3">
      <vs-row vs-align="center" class="pt-4 pb-4">
        <vs-col :vs-w="current && current !== actual ? '2' : '3'">
          <ul class="leftx">
            <li
              v-for="t in lang.users.generalSettings
                .passwordExpirationOptions_0"
              :key="t.milliseconds"
              class="mb-2"
            >
              <vs-radio v-model="passwordExpiration" :vs-value="t.milliseconds">
                <span v-if="t.milliseconds">{{
                  t.milliseconds | duration('humanize')
                }}</span>
                <span v-else>{{ t[languageSelected] }}</span>
              </vs-radio>
            </li>
          </ul>
        </vs-col>
        <vs-col :vs-w="current && current !== actual ? '2' : '3'">
          <ul class="leftx">
            <li
              v-for="t in lang.users.generalSettings
                .passwordExpirationOptions_1"
              :key="t.milliseconds"
              class="mb-2"
            >
              <vs-radio v-model="passwordExpiration" :vs-value="t.milliseconds">
                <span v-if="t.milliseconds">{{
                  t.milliseconds | duration('humanize')
                }}</span>
                <span v-else>{{ t[languageSelected] }}</span>
              </vs-radio>
            </li>
          </ul>
        </vs-col>
        <vs-col :vs-w="current && current !== actual ? '2' : '3'">
          <ul class="leftx">
            <li
              v-for="t in lang.users.generalSettings
                .passwordExpirationOptions_2"
              :key="t.milliseconds"
              class="mb-2"
            >
              <vs-radio v-model="passwordExpiration" :vs-value="t.milliseconds">
                <span v-if="t.milliseconds">{{
                  t.milliseconds | duration('humanize')
                }}</span>
                <span v-else>{{ t[languageSelected] }}</span>
              </vs-radio>
            </li>
          </ul>
        </vs-col>
        <vs-col vs-w="3">
          <ul class="roles float-right">
            <li class="mb-2">
              <vs-checkbox
                :disabled="!session.user.roles.canCreate.supervisor"
                v-model="roles[userRoles['SUPERVISOR']]"
                :vs-value="true"
                name="supervisor"
              >
                {{
                  rolesAvailable[userRoles.SUPERVISOR].name[languageSelected]
                }}
              </vs-checkbox>
            </li>
            <li class="mb-2">
              <vs-checkbox
                :disabled="!session.user.roles.canCreate.editor"
                v-model="roles[userRoles['EDITOR']]"
                :vs-value="true"
                name="editor"
                >{{
                  rolesAvailable[userRoles.EDITOR].name[languageSelected]
                }}</vs-checkbox
              >
            </li>
            <li class="mb-2">
              <vs-checkbox
                :disabled="!session.user.roles.canCreate.agent"
                v-model="roles[userRoles['AGENT']]"
                :vs-value="true"
                name="agent"
                >{{
                  rolesAvailable[userRoles.AGENT].name[languageSelected]
                }}</vs-checkbox
              >
            </li>
            <li class="mb-2">
              <vs-checkbox
                :disabled="!session.user.roles.canCreate.supervisor"
                v-model="roles[userRoles['ROLES_MANAGER']]"
                :vs-value="true"
                name="rolesManager"
              >
                {{
                  rolesAvailable[userRoles.ROLES_MANAGER].name[languageSelected]
                }}
              </vs-checkbox>
            </li>
          </ul>
        </vs-col>
        <vs-col vs-w="3" v-if="current && current !== actual">
          <vs-button @click="update" class="float-right mb-2">{{
            lang.users.generalSettings.save[languageSelected]
          }}</vs-button>
          <vs-button class="float-right" type="border" @click="cancel">{{
            lang.users.generalSettings.cancel[languageSelected]
          }}</vs-button>
        </vs-col>
      </vs-row>
    </div>
  </vs-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Roles from '../../../../models/Roles'
import { USER_ROLES } from '../../../../models/Roles/UserRoles'

export default {
  name: 'PasswordValidity',
  data() {
    return {
      rolesAvailable: Roles,
      userRoles: USER_ROLES,
      passwordExpiration: null,
      roles: {
        [USER_ROLES['SUPERVISOR']]: false,
        [USER_ROLES['EDITOR']]: false,
        [USER_ROLES['AGENT']]: false,
        [USER_ROLES['ROLES_MANAGER']]: false
      },
      current: null
    }
  },
  computed: {
    ...mapState(['languageSelected', 'lang', 'session', 'passwordValidity']),
    actual() {
      const result = JSON.stringify({
        roles: this.roles,
        passwordExpiration: this.passwordExpiration
      })
      return result
    }
  },
  methods: {
    ...mapActions(['getCompanySettings', 'saveCompanyPasswordValidity']),
    update() {
      this.$vs.loading()

      this.saveCompanyPasswordValidity({
        time: this.passwordExpiration,
        roles: Object.keys(this.roles).filter(key => this.roles[key])
      })
        .then(() => {
          // StorageService.update('session', 'theme', this.theme)
          this.$vs.notify({
            color: 'success',
            title: this.lang.users.generalSettings.notify.success.title[
              this.languageSelected
            ],
            text: this.lang.users.generalSettings.notify.success.text[
              this.languageSelected
            ]
          })
          this.current = JSON.stringify({
            roles: this.roles,
            passwordExpiration: this.passwordExpiration
          })
        })
        .catch(error => {
          this.$vs.notify({
            color: 'danger',
            title: this.lang.users.generalSettings.notify.error.title[
              this.languageSelected
            ],
            text: this.lang.users.generalSettings.notify.error.text[
              this.languageSelected
            ]
          })
          this.$log.error(error)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    cancel() {
      this.roles = JSON.parse(this.current).roles
      this.passwordExpiration = JSON.parse(this.current).passwordExpiration
    }
  },
  async mounted() {
    await this.getCompanySettings()
    this.passwordExpiration = this.passwordValidity.time
    this.passwordValidity.roles.forEach(id => {
      this.roles[id] = true
    })
    this.current = JSON.stringify({
      roles: this.roles,
      passwordExpiration: this.passwordExpiration
    })
  }
}
</script>
