const { Parser } = require('json2csv')

/**
 *
 * @param {*} filename Name to be exported (No include .csv)
 * @param {*} fields @type string[]
 * @param {*} data  @type Array[] data to be exported, fields should be the keys
 */
const downloadCsv = function(filename, fields, data) {
  function saveData(blob, fileName) {
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }

  const json2csvParser = new Parser({ fields })
  const csv = json2csvParser.parse(data)

  var BOM = '\uFEFF'
  var csvData = BOM + csv

  var blob = new Blob([csvData], {
    type: 'text/plain; encoding=utf-8'
  })

  saveData(blob, `${filename}.csv`)
}

export default downloadCsv
