import Request from './request'
import state from '../store/state'

const PATH = {
  STAFF_ROLES: '/staffRole',
  STAFF_PERMISSIONS: '/staffPermission'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getStaffPermissions = async () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.get(`${state.config.konecta}${PATH.STAFF_PERMISSIONS}`, config)
}

const getStaffRoles = async () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.get(`${state.config.konecta}${PATH.STAFF_ROLES}`, config)
}

const getStaffRolesByUser = async id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.get(
    `${state.config.konecta}${PATH.STAFF_ROLES}/user/${id}`,
    config
  )
}

const getStaffRole = async id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.get(`${state.config.konecta}${PATH.STAFF_ROLES}/${id}`, config)
}

const createStaffRole = async payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.post(
    `${state.config.konecta}${PATH.STAFF_ROLES}`,
    payload,
    config
  )
}

const updateStaffRole = async (staffRoleId, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.put(
    `${state.config.konecta}${PATH.STAFF_ROLES}/${staffRoleId}`,
    payload,
    config
  )
}

const deleteStaffRole = async staffRoleId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.delete(
    `${state.config.konecta}${PATH.STAFF_ROLES}/${staffRoleId}`,
    config
  )
}

export default {
  getStaffPermissions,
  getStaffRoles,
  getStaffRolesByUser,
  getStaffRole,
  createStaffRole,
  updateStaffRole,
  deleteStaffRole
}
